import React from "react";
import onlineLogo from "../img/online.png";
import offlineLogo from "../img/offline.png";
import i18next from "i18next";

export default function FormatIcons({type, changeMode=null, canChange = false}) {
    
    const pressText = canChange?' '+i18next.t("press_to_change"):''
    
    
  return (
   <div
          onClick={changeMode}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {type == "online" && (
            <div title={i18next.t("online_type")+pressText}>
              <img src={onlineLogo} />
            </div>
          )}
          {type == "offline" && (
            <div title={i18next.t("offline_type")+pressText}>
              <img src={offlineLogo} />
            </div>
          )}
          {type == "hybrid" && (
            <div title={i18next.t("online_plus_offline_type")+pressText}>
              <img src={onlineLogo} />
            </div>
          )}
          {type == "hybrid" && (
            <div title={i18next.t("online_plus_offline_type")+pressText}
              style={{ marginLeft: 3 }}
            >
              <img src={offlineLogo} />
            </div>
          )}
        </div>
  );
}
