import React from "react";
import "../css/style.css";

import { CircularProgress } from "@material-ui/core";
import i18next from "i18next";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { userService } from "../_services";
import PartItem from "./PartItem";
import QRDialog from "./QRDialog";

class TicketsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, tickets:[], nameF:'',dateF:'',qrData:null,openQR:false };
  }

  componentDidMount() {
    //this.props.dispatch(userActions.conf_student_list(0));
    this.setState({ lang: this.props.app.lang });
    this.LoadTickets();
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.LoadTickets();
    }
  }
  
  LoadTickets() {
      
      let self = this;
        userService.getWrapper('api/ticket/',
        (data) => {
           //self.props.dispatch(userActions.conf_list());
          //confService.confListParticipants(false);
          
           const ticket_data = data.data.map(function(item){

               const conf = item.ticket_type.conference;
               
               const confName = conf.name;
                    const conf_dates = `${conf.start_time
                      .substr(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")} - ${conf.end_time
                      .substr(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}`;
               
               return {id:item.id,conf_name:confName,dates:conf_dates,name:item.ticket_type.name,cost:item.ticket_type.cost}
               
               
           });               
           
           self.setState({tickets:ticket_data});
           
        },
        (error) => {
            self.props.dispatch(userActions.set_error(error));
            
        }
      );
  }
  
  ticketClick = (id) => {
	  const { classes, dispatch } = this.props;
	  
	  userService.getWrapper(
      "api/ticket/"+id,
      (data) => {
        
		console.log(data);
		if (data.data.img)
		{
			this.setState({qrData:data.data.img,openQR:true});
		}
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  }

  render() {
    const self = this;
    const { isLoading, tickets, nameF,dateF, qrData, openQR } = this.state;
    const { classes, user } = this.props;

    

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t('tickets')}</h1>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--city">
                <input type="text" placeholder={i18next.t("search_for")+" "+i18next.t("search_type_confs") } value={nameF} onChange={(e)=>this.setState({nameF:e.target.value})}/>
              </div>
              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder={i18next.t("date_search")} value={dateF} onChange={(e)=>this.setState({dateF:e.target.value})}/>
              </div>
              
            </div>
          </div>

          <div className="tbl__body">
        {tickets.filter(e=>e.conf_name.indexOf(nameF)>=0).filter(e=>e.dates.indexOf(dateF)>=0).map((item, index) => (
          <PartItem data={item} onItemClick={self.ticketClick} qr_text={i18next.t("press_to_see_qr")}/>
        ))}
      
          </div>
        </div>
		<QRDialog
		  handleClose={() => self.setState({ openQR: false, qrData:null })}
          qrData={qrData}
          dialogOpen={openQR}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedTicketsPage = connect(mapStateToProps)(TicketsPage);
export { connectedTicketsPage as TicketsPage };

