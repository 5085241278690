import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import "../css/style.css";
import offlineLogo from "../img/offline.png";
import onlineLogo from "../img/online.png";
import { ConstructionOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  maxWidth: 1200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 10,
  p: 4,
  fontSize: 16,
};

const headerWidth = 140;


function replaceName(name) {
  if (!name) return name;

  return name;
}

function getUrls(s0) {
  console.log(s0);
  if (!s0) return;
  let s = s0;

  var re =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  let array = [...s.matchAll(re)];

  for (let i = 0; i < array.length; i++) {
    let url_tmp = array[i][0] + "";
    let url_correct = url_tmp;

    if (!url_tmp.includes("https://") && !url_tmp.includes("http://")) {
      url_correct = "https://" + url_tmp;
    }

    s0 = s0.replace(
      url_tmp,
      '<span style="color: blue" ><a href="' +
        url_correct +
        '" target="_blank">' +
        url_tmp +
        "</a></span>"
    );
  }

  s0 = "<div>" + s0 + "</div>";
  console.log(s0);
  return s0;
}

export default function SectionInfo(props) {
  const [count, setCount] = React.useState(0);
  
  const {
    handleClose,
    data,
    open,
    isPublic,
    redirectToConf,
  } = props;

  const no_text = props.texts.to_be_specified;
  const no_url_text = props.texts.viewtrans;


  React.useEffect(() => {});

  function openUrl() {
    if (data.description.url) {
      if (
        data.description.url.indexOf("https:") != 0 &&
        data.description.url.indexOf("http:") != 0
      )
        window.open("https://" + data.description.url);
      else window.open(data.description.url);
    }
  }

  console.log(data);

  /*<div
  dangerouslySetInnerHTML={{ __html: getUrls(data.description.description ?? '') }}
  ></div>
  */

  if (!data) return;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            minWidth: props.isMobile ? window.screen.width - 10 : 600,
            maxWidth: props.isMobile ? window.screen.width - 10 : 1200,
          }}
        >
          {!props.isMobile && (
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>{props.texts.theme}:</div>{" "}
              {data.name ? replaceName(data.name) : no_text}
            </Typography>
          )}
          {props.isMobile && (
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.theme}:
                </div>
              </Typography>
              <div> {data.name ? replaceName(data.name) : no_text}</div>
            </>
          )}

          {data.description && data.description.description && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", minWidth: headerWidth }}>
              {props.texts.desc}:
              </div>
              <div>
                {data.description.description}
                </div>

            </Typography>
          )}

          {data.slot_type != "service_lesson" && !props.isMobile && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
              s
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>
              {props.texts.speakers}:
              </div>{" "}
              {data.moderators ? data.moderators : no_text}
            </Typography>
          )}
          {data.slot_type != "service_lesson" && props.isMobile && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
                
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.speakers}:
                </div>
              </Typography>
              <div> {data.moderators ? data.moderators : no_text}</div>
            </>
          )}
          {data.description && data.description.room && !props.isMobile && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>{props.texts.room}:</div>{" "}
              {(data.description && data.description.room) ? data.description.room : no_text}
            </Typography>
          )}
          { data.description && data.description.room &&  props.isMobile && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                  {props.texts.room}:
                </div>
              </Typography>
              <div>{(data.description && data.description.room) ? data.description.room : no_text}</div>
            </>
          )}
          {!props.isMobile && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.time}:
              </div>{" "}
              {data.description && data.description.time ? data.description.time : no_text}
            </Typography>
          )}
          {props.isMobile && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.time}:
                </div>
              </Typography>
              <div> {data.description && data.description.time ? data.description.time : no_text}</div>
            </>
          )}
          
          {data.description && data.description.url && !isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                width: 250,
              }}
            >
              <span onClick={openUrl}>{props.texts.urltrans}</span>
            </Typography>
          )}

          {data.description &&  data.description.url && isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <span onClick={redirectToConf}>{no_url_text}</span>
            </Typography>
          )}
        
        </Box>
      </Fade>
    </Modal>
  );
}
