import React from "react";
import "../css/style.css";
import { connect } from "react-redux";
import i18next from "i18next";

import { userActions, appActions } from "../_actions";

import ClientConfItem from "./ClientConfItem";
import { store } from "../_helpers";

import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";

class ClientListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPublic: false,
      start_date: "",
      end_date: "",
      isNoMenu: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    let token = localStorage.getItem("token");

    if (!token) {
      this.setState({ isPublic: true });
    }

    if (window.location.href.indexOf("/events") >= 0)
      this.setState({ isNoMenu: true });

    let lang = localStorage.getItem("navic_lang") ?? "ru";

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });

    this.setState({ lang: this.props.app.lang });

    //history.push('/login');

    this.props.dispatch(userActions.conf_student_list());
    // confService.confListParticipants(true);
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.props.dispatch(userActions.conf_student_list());
    }
  }

  storeChange = (e) => {};

  onItemClick = (e) => {};

  onItemEdit = (e) => {
    if (!this.state.isPublic) {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("conf_view", e.id));
    } else {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("event", e.id));
    }
  };

  onItemDelete = (e) => {};

  changeDate = (index, event) => {
    if (index === 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  resetDateFilter = () => {
    this.setState({ end_date: "", start_date: "" });
    this.forceUpdate();
  };

  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({ lang });

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });
  };

  render() {
    const self = this;
    const { start_date, end_date, lang, isNoMenu } = this.state;
    const { user } = this.props;

    let isMobile = window.innerWidth < 800;
    console.log(isMobile, window);

    return (
      <div className="layout">
        <div className="layout__contains">
          {isNoMenu && (
            <div className="lang" style={{ marginRight: 20 }}>
              <button className="lang__btn" onClick={this.changeLang}>
                {lang == "ru" && (
                  <img src={ruFlag} alt="" className="lang__flag" />
                )}
                {lang == "en" && (
                  <img src={enFlag} alt="" className="lang__flag" />
                )}
                {lang == "cn" && (
                  <img src={cnFlag} alt="" className="lang__flag" />
                )}
                <span style={{ fontSize: 16 }}>{lang}</span>
              </button>
            </div>
          )}
          <h1 className="layout__title">{i18next.t("confs_list")}</h1>
          <div className="date-wrap form__group-row" style={{ flex: 0 }}>
            <label htmlFor="start">{i18next.t("date_from")}</label>
            <input
              type="date"
              className="form__input"
              id="start"
              value={start_date}
              name="trip-start"
              onChange={(e) => this.changeDate(0, e)}
              style={{ width: 200 }}
            />
          </div>

          <div className="date-wrap form__group-row">
            <label htmlFor="start"> {i18next.t("date_to")}</label>
            <input
              type="date"
              className="form__input"
              id="start"
              value={end_date}
              name="trip-start"
              onChange={(e) => this.changeDate(1, e)}
              style={{ width: 200 }}
            />

            <button
              style={{ marginLeft: 20, width: 200 }}
              className="btn"
              onClick={this.resetDateFilter}
            >
              {i18next.t("reset_dates")}
            </button>
          </div>
        </div>

        <ul className="conf-list">
          {user.conflist_student.length > 0 &&
            user.conflist_student
              .filter(
                (e) => !start_date || e.start_time.substr(0, 10) > start_date
              )
              .filter((e) => !end_date || e.end_time.substr(0, 10) < end_date)
              .map((item, index) => (
                <ClientConfItem
                  data={item}
                  key={index}
                  onClick={() => self.onItemClick(item.id)}
                  onEdit={() => self.onItemEdit(item)}
                  onDelete={() => self.onItemDelete(item.id)}
                  isMobile={isMobile}
                />
              ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedClientListPage = connect(mapStateToProps)(ClientListPage);
export { connectedClientListPage as ClientListPage };
