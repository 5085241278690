import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation, Trans } from "react-i18next";
import "../css/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@mui/material/Tooltip";
import RightIcon from "@material-ui/icons/ArrowRight";
import LeftIcon from "@material-ui/icons/ArrowLeft";
import themeLogo from "../img/theme.png";
import spLogo from "../img/sp.png";
import roomLogo from "../img/rooms.png";

export default function DragMenu(props) {
  const {
    themes,
    service_themes,
    speakers,
    rooms,
    isOpen,
    onDragClick,
    addThemeDialog,
    editTheme,
    delTheme,
    editServiceTheme,
    delServiceTheme,
    addSpeakerDialog,
    editSpeaker,
    delSpeaker,
    addRoomDialog,
    editRoom,
    delRoom,
    format,
  } = props;

  const { t, i18n } = useTranslation();

  const [curTab, setTab] = React.useState("theme");
  const [themeFilter, setThemeFilter] = React.useState("");
  const [speakerFilter, setSpeakerFilter] = React.useState("");
  const [roomFilter, setRoomFilter] = React.useState("");

  return (
    <div className={isOpen ? "constructor-tabs open" : "constructor-tabs"}>
      <div className="tabs-nav">
        <ul className="top-links">
          <li
            className="tab-link active"
            onClick={() => setTab("theme")}
            style={{
              backgroundColor: curTab == "theme" ? "#36B2F1" : "#ffffff",
              borderRadius: 15,
            }}
          >
            <img src={themeLogo} style={{ marginLeft: 5, marginBottom: 5 }} />
            <span className="link-name">{t("themes")}</span>
          </li>
          <li
            className="tab-link"
            onClick={() => setTab("speaker")}
            style={{
              backgroundColor: curTab == "speaker" ? "#36B2F1" : "#ffffff",
              borderRadius: 15,
            }}
          >
            <img src={spLogo} style={{ marginLeft: 5, marginBottom: 5 }} />
            <span className="link-name">{t("speakers")}</span>
          </li>
          {format != "online" && (
            <li
              className="tab-link"
              onClick={() => setTab("room")}
              style={{
                backgroundColor: curTab === "room" ? "#36B2F1" : "#ffffff",
                borderRadius: 15,
              }}
            >
              <img src={roomLogo} style={{ marginLeft: 12, marginBottom: 5 }} />
              <span className="link-name">{t("rooms")}</span>
            </li>
          )}
          <div className="top-links__active" />
        </ul>

        <div className="toggle-tabs" onClick={() => onDragClick()}>
          {isOpen ? <RightIcon /> : <LeftIcon />}
        </div>
      </div>
      <div className="tabs-content" style={{ width: isOpen ? 300 : 0 }}>
        <div className="tab active">
          <div className="search-tab">
            <div>
              {curTab == "theme" && (
                <div className="form">
                  <label htmlFor="filter-theme">
                    <i className="icon-search-two" />
                  </label>
                  <input
                    id="filter-theme"
                    value={themeFilter}
                    onChange={(e) => setThemeFilter(e.target.value)}
                    title={t("filter_for") + " " + t("filter_type_themes")}
                    placeholder={t("search")}
                  />
                  <button
                    className="calendar__body-add"
                    onClick={addThemeDialog}
                    title={t("add_theme")}
                  >
                    <i className="icon-plus-border" />
                  </button>
                </div>
              )}

              {curTab == "speaker" && (
                <div className="form">
                  <label htmlFor="filter-speaker">
                    <i className="icon-search-two" />
                  </label>
                  <input
                    id="filter-speaker"
                    value={speakerFilter}
                    placeholder={t("search")}
                    onChange={(e) => setSpeakerFilter(e.target.value)}
                    title={t("filter_for") + " " + t("filter_type_speakers")}
                  />
                  <button
                    className="calendar__body-add"
                    onClick={addSpeakerDialog}
                    title={t("add_speaker")}
                  >
                    <i className="icon-plus-border" />
                  </button>
                </div>
              )}

              {curTab == "room" && (
                <div className="form">
                  <label htmlFor="filter-room">
                    <i className="icon-search-two" />
                  </label>
                  <input
                    id="filter-room"
                    placeholder={t("search")}
                    value={roomFilter}
                    onChange={(e) => setRoomFilter(e.target.value)}
                    title={t("filter_for") + " " + t("filter_type_rooms")}
                  />
                  <button
                    className="calendar__body-add"
                    onClick={addRoomDialog}
                    title={t("add_room")}
                  >
                    <i className="icon-plus-border" />
                  </button>
                </div>
              )}
            </div>
          </div>
          {curTab == "theme" && (
            <Droppable droppableId="theme">
              {(provided) => (
                <ul
                  className="list-tab"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {themes
                    .filter((e) => !e.selected)
                    .filter(
                      (e) =>
                        (e.name && e.name.indexOf(themeFilter) > -1) || !e.name
                    )
                    .map((item, index) => (
                      <Draggable
                        key={`${item.id}`}
                        draggableId={`theme_${item.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="calendar__item"
                          >
                            <Tooltip
                              title={
                                <span style={{ fontSize: 20 }}>
                                  {item.name}
                                </span>
                              }
                              arrow
                            >
                              <div className="calendar__body-block">
                                <span
                                  className="input"
                                  title={t("move_to_timeslot")}
                                >
                                  {item.name}
                                </span>
                                <div className="option-icons">
                                  <div title={t("edit_theme")}>
                                    <EditIcon
                                      width="15"
                                      height="15"
                                      onClick={() => editTheme(item)}
                                    />
                                  </div>
                                  <div title={t("del_theme")}>
                                    <DeleteIcon
                                      width="15"
                                      height="15"
                                      onClick={() => delTheme(item.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          </li>
                        )}
                      </Draggable>
                    ))}

                  <li>{t("service_lessons")}:</li>

                  {service_themes.map((item, index) => (
                    <Draggable
                      key={`${item.id}`}
                      draggableId={`servicetheme_${item.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="calendar__item"
                        >
                          <Tooltip
                            title={
                              <span style={{ fontSize: 20 }}>{item.name}</span>
                            }
                            arrow
                          >
                            <div className="calendar__body-block">
                              <span
                                className="input"
                                title={t("move_to_timeslot")}
                              >
                                {item.name}
                              </span>
                              {item.user_id && (
                                <div className="option-icons">
                                  <div title={t("edit_service_theme")}>
                                    <EditIcon
                                      width="15"
                                      height="15"
                                      onClick={() => editServiceTheme(item.id)}
                                    />
                                  </div>
                                  <div title={t("del_theme")}>
                                    <DeleteIcon
                                      width="15"
                                      height="15"
                                      onClick={() => delServiceTheme(item.id)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        </li>
                      )}
                    </Draggable>
                  ))}
                </ul>
              )}
            </Droppable>
          )}

          {curTab == "speaker" && (
            <Droppable droppableId="speakers">
              {(provided) => (
                <ul
                  className="list-tab"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {speakers
                    .filter((e) => e.name.indexOf(speakerFilter) > -1)
                    .map((item, index) => (
                      <Draggable
                        key={`${item.id}`}
                        draggableId={`speaker_${item.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="calendar__item"
                          >
                            <div
                              className="calendar__body-block"
                              title={item.description}
                            >
                              <span className="input">{item.name}</span>
                              <div className="option-icons">
                                <div title={t("edit_speaker")}>
                                  <EditIcon
                                    width="15"
                                    height="15"
                                    onClick={() => editSpeaker(item.id)}
                                  />
                                </div>
                                <div title={t("del_speaker")}>
                                  <DeleteIcon
                                    width="15"
                                    height="15"
                                    onClick={() => delSpeaker(item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              )}
            </Droppable>
          )}

          {curTab == "room" && (
            <Droppable droppableId="rooms">
              {(provided) => (
                <ul
                  className="list-tab"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {rooms
                    .filter((e) => e.number.indexOf(roomFilter) > -1)
                    .map((item, index) => (
                      <Draggable
                        key={`${item.id}`}
                        draggableId={`room_${item.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="calendar__item"
                          >
                            <div className="calendar__body-block">
                              <span className="input">{item.number}</span>
                              <div className="option-icons">
                                <div title={t("edit_room")}>
                                  <EditIcon
                                    width="15"
                                    height="15"
                                    onClick={() => editRoom(item.id)}
                                  />
                                </div>
                                <div title={t("del_room")}>
                                  <DeleteIcon
                                    width="15"
                                    height="15"
                                    onClick={() => delRoom(item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                </ul>
              )}
            </Droppable>
          )}
        </div>
      </div>
    </div>
  );
}
