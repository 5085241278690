import React from "react";
import { SvgButton } from "./SvgButton";

export class EditButton extends SvgButton {
  handleClick = (e) => {
    if (this.props.onClick) {
      e.stopPropagation();
      this.props.onClick(e);
    }
  };

  render() {
    const { id, name } = this.props;
    return (
      <SvgButton
        id={id}
        name={name || "Редактировать"}
        path="M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z"
        onClick={this.handleClick}
      />
    );
  }
}
