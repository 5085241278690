import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";

export default function RoleDialog(props) {
  const [roleId, setRole] = React.useState(0);
  const [confId, setConf] = React.useState(0);
  const [teamId, setTeam] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    roles_list,
    conf_list,
    dialogOpen,
    old_confId,
    old_roleId,
	id,
    needPeople = false,
    team = [],
  } = props;

  const { t} = useTranslation();

  React.useEffect(() => {
    if (count == 0) {
      setConf(old_confId);
      setRole(old_roleId);
      setTeam(id);
    }
  });

  function selectRole(e) {
    setCount(count + 1);
    setRole(e);
  }

  function selectConf(e) {
    setCount(count + 1);
    setConf(e);
  }

  function selectTeam(e) {
    setCount(count + 1);
    setTeam(e);
  }

  function saveData() {
    console.log(roleId, confId);

    if (roleId > 0) {
      if (needPeople) {
        if (teamId > 0) handleClose({ roleId, confId, teamId });
      } else handleClose({ roleId, confId, teamId: 0 });

      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>
        {old_confId === 0 ? t("add_role") : t("edit_role")}
      </DialogTitle>
      <form>
        <DialogContent>
          {needPeople && (
            <div style={{ paddingBottom: 10 }}>
              <select onChange={(e) => selectTeam(e.target.value)}>
                <option value={0} selected={roleId == 0}>
                  {t("select_team_membe")}
                </option>
                {team.map((item, index) => (
                  <option value={item.id} selected={teamId == item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div style={{ paddingBottom: 10 }}>
            <select onChange={(e) => selectRole(e.target.value)}>
              <option value={0} selected={roleId == 0}>
              {t("select_role")}
              </option>
              {roles_list.map((item, index) => (
                <option value={item.id} selected={roleId == item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <span>{t("select_conf")}</span>
          <select onChange={(e) => selectConf(e.target.value)}>
            <option value={0} selected={confId == 0}>
              {t("without_event")}
            </option>
            {conf_list.map((item, index) => (
              <option value={item.id} selected={confId == item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveData}>{t("btn_save")}</Button>
          <Button onClick={cancelData}>{t("btn_cancel")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
