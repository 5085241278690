import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { serverUrl } from "../_constants";
import "../css/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  minWidth: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SpeakerInfo(props) {
  const { handleClose, data, open, texts } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {data.photo && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ maxHeight: 300, width: "auto", marginBottom: 10 }}
                src={data.photo ? serverUrl + data.photo : ""}
                alt=""
                className="object-cover"
              />
            </div>
          )}
          <Typography id="transition-modal-title" variant="h5" component="h2">
             {data.name ? data.name : texts.no_text}
          </Typography>
          {data.city && (
            <Typography id="transition-modal-title" component="h2">
              {texts.city}: {data.city}
            </Typography>
          )}
          <Typography
            id="transition-modal-description"
            sx={{ mt: 1 }}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {data.description ? data.description : texts.no_text}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}
