import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import "../css/style.css";
import personLogo from "../img/person.png";
import { useTranslation } from "react-i18next";

export default function TeamItem(props) {

  const { t} = useTranslation();

  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div
          className="tbl__item tbl__item--name"
          onClick={() => props.showInfo(props.data)}
        >
          <span className="profile">
            <img src={personLogo} alt="" className="profile__img" />
            <span className="profile__name">
              {props.data ? props.data.name : ""}
            </span>
          </span>
        </div>

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.email : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.phone : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.city : ""}</span>
        </div>

        <div className="item-edit">
          {props.isUser && props.canRole && (
            <div
              title={t("add_role")}
              style={{ width: 20, marginLeft: 10, marginRight: 10, cursor:'pointer' }}
            >
              <AddIcon
                width="15"
                height="15"
                onClick={(e) => props.userAction(props.data.id)}
              />
            </div>
          )}

          {props.canRole && props.data.type !=="org" && <div
            style={{ width: 20, marginLeft: 10, marginRight: 10, cursor:'pointer' }}
          >
            <EditIcon
              width="15"
              height="15"
              onClick={(e) =>
                props.isUser
                  ? props.userEdit(props.data.id)
                  : props.roleAction(props.data)
              }
            />
          </div>}
          {props.canRole && props.data.type !="org" && <div  style={{ width: 20, cursor:'pointer' }}>
            <DeleteIcon
              width="15"
              height="15"
              onClick={() => props.delAction(props.data)}
            />
          </div>}
        </div>
      </div>
    </div>
  );
}
