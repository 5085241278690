import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import EyeIcon from "@material-ui/icons/Visibility";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";

export default function RoleListDialog(props) {
  const {
    handleClose,
    editRole,
    addRole,
    delRole,
    dialogOpen,
    roles_list,
    openRules,
  } = props;

  const sysRoles = ["admin", "manager", "analyst", "place_manager"];

  const [count, setCount] = React.useState(0);
  const [rolesNames, setRolesNames] = React.useState([]);
  const [rolesOld, setRolesOld] = React.useState([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    console.log(count, roles_list);

    if (count == 0) {
      setRolesNames(roles_list);
      setRolesOld(roles_list);
      setCount(1);
    } else if (roles_list.length != rolesOld.length) {
      setRolesNames(roles_list);
      setRolesOld(roles_list);
    }
  });

  function editRules(ind) {
    console.log(ind);
    openRules(roles_list[ind]);
  }

  function editRoleStr(ind, e) {
    const newRoles = [...rolesNames];
    newRoles[ind].name = e;
    setRolesNames(newRoles);
  }

  function saveRole(ind) {
    const { id } = rolesNames[ind];
    const e = rolesNames[ind].name;

    if (id > 0 && e) {
      setCount(0);
      editRole(id, e);
    } else if (id == 0 && e) {
      setCount(0);
      addRole(e);
    }
  }

  function delRoleStr(ind) {
    const { id } = rolesNames[ind];

    if (id > 0) {
      setCount(0);
      delRole(id);
    } else {
      const newRoles = [...rolesNames];

      newRoles.splice(ind, 1);
      setRolesNames(newRoles);
    }
  }

  function addString() {
    const newRoles = [...rolesNames];
    newRoles.push({ id: 0, name: "" });
    setRolesNames(newRoles);
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{t("roles_list")}</DialogTitle>
      <DialogContent>
        <div className="form__group" style={{ width: 400 }}>
          <div className="form__group-body">
            <div className="form__group-top">
              <button type="button" className="form__btn" onClick={addString}>
                <svg width="29" height="29" />
                <span>{t("btn_add")}</span>
              </button>
            </div>
            {rolesNames &&
              rolesNames.map((item, index) => {
                let isSys = false;

                if (sysRoles.indexOf(item.name) >= 0) isSys = true;

                return (
                  <div className="form__group-rows">
                    <div className="form__group-row">
                      <input
                        type="text"
                        onChange={(e) => editRoleStr(index, e.target.value)}
                        className="form__input"
                        placeholder={t("title")}
                        value={item.name}
                      />
                    </div>
                    {isSys == false && (
                      <div style={{ width: 30 }}>
                        <SaveIcon
                          onClick={() => saveRole(index)}
                          width="15"
                          height="15"
                        />
                      </div>
                    )}
                    {isSys == false && item.id > 0 && (
                      <div style={{ width: 30 }}>
                        <EditIcon
                          onClick={() => editRules(index)}
                          width="15"
                          height="15"
                        />
                      </div>
                    )}
                    {isSys && item.id > 0 && (
                      <div style={{ width: 30 }}>
                        <EyeIcon
                          onClick={() => editRules(index)}
                          width="15"
                          height="15"
                        />
                      </div>
                    )}
                    {isSys == false && (
                      <div style={{ width: 30 }}>
                        <DeleteIcon
                          onClick={() => delRoleStr(index)}
                          width="15"
                          height="15"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelData}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
