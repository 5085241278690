import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function MoveDialog(props) {
  const [sect, setSect] = React.useState("");
  const [count, setCount] = React.useState(0);

  const { handleClose, dialogOpen, sect_list, sect_id, text_save, text_cancel, text_select_session } = props;

  React.useEffect(() => {
    if (count === 0) {
      setSect("");
    }
  },[count]);

  function selectSect(e) {
    setSect(e);
    setCount(1);
  }

  function saveData() {
    handleClose(sect);
    setCount(0);
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{text_select_session}</DialogTitle>
      <DialogContent>
        <select onChange={(e) => selectSect(e.target.value)}>
          <option value={0} selected>
           {text_select_session}
          </option>
          {sect_list
            .filter((e) => e.id != sect_id)
            .map((item, index) => (
              <option value={item.id}>{item.name}</option>
            ))}
        </select>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{text_save}</Button>
        <Button onClick={cancelData}>{text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
