import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

const sysRoles = ["admin", "manager", "analyst", "place_manager"];

export default function RulesDialog(props) {
  const [perm, setPerm] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const { t } = useTranslation();
  const { handleClose, rolePerm, fullPerm, roleName, dialogOpen } = props;

  React.useEffect(() => {
    if (count == 0) {
      console.log(roleName);
      console.log(rolePerm);
      setPerm(rolePerm);
    }
  });

  function saveData(e) {
    e.preventDefault();

    console.log(perm);
    handleClose(perm);
    setCount(0);
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  function checkRule(ind) {
    if (sysRoles.indexOf(roleName) < 0) {
      const { val } = perm[ind];
      const tmpP = [...perm];
      tmpP[ind].val = !val;

      setCount(count + 1);
      setPerm(tmpP);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{t("rights_for_role") + " " + roleName}</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent>
          <FormGroup>
            {perm.length > 0 &&
              perm.map((item, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.val}
                      onClick={() => checkRule(index)}
                    />
                  }
                  label={t("role" + item.name)}
                />
              ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {sysRoles.indexOf(roleName) < 0 && (
            <Button type="submit">{t("btn_save")}</Button>
          )}
          <Button onClick={cancelData}>{t("btn_cancel")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
