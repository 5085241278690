import React from "react";
import "../css/style.css";

export default function PartItem(props) {
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div className="tbl__item tbl__item--city" title={props.qr_text} onClick={()=>props.onItemClick(props.data.id)}>
          <span style={{textDecoration:'underline'}}>{props.data ? props.data.conf_name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.dates : ""}</span>
        </div>
		
      </div>
    </div>
  );
}
