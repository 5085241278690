import i18next from "i18next";
import React from "react";
import "../css/style.css";

export default function ConfPlaceholder(props) {
  return (
    <li className="conf-list__item">
      <div className="card" style={{ minHeight: 300 }}>
        <button
          className="block-dropdwon__btn"
          style={{ width: "100%", height: "100%", fontSize: 20 }}
          onClick={props.onClick}
        >
          {i18next.t("create_event")}
        </button>
      </div>
    </li>
  );
}
