import React from "react";
import "../css/style.css";
import "../css/constructor.css";

export default function SlotPlaceholder(props) {
  return (
    <div
      className="line-constructor"
      style={{ height: 50, justifyContent: "space-around" }}
    >
      <button
        className="block-dropdwon__btn"
        style={{ width: "40%", fontSize: 12 }}
        onClick={props.onAddSlot}
      >
        {props.text_add_slot}
      </button>
      <button
        className="block-dropdwon__btn"
        style={{ width: "40%", fontSize: 12 }}
        onClick={props.onAddSection}
      >
        {props.text_add_section}
      </button>
    </div>
  );
}
