import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldI18N from "../_components/TextFieldI18N";
import Autocomplete from "@mui/material/Autocomplete";
const LANG_LIST = ["ru", "en", "cn"];

export default function ModeratorsDialog(props) {
  const [count, setCount] = React.useState(0);
  const [moderators, setModers] = React.useState([]);

  const {
    handleClose,
    dialogOpen,
    text_cancel,
    text_save,
    text_section_name,
    text_moderator,
    text_add,
    speakers,
    mainIndex,
    allSpeakers,
  } = props;

  React.useEffect(() => {
    setModers([...speakers]);
  }, [speakers]);

  function saveData() {
    handleClose(moderators);
    setCount(0);
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  function onSpeakerTextChange(e, i) {
    let tmp_moders = [...moderators];
    if (e) {
      tmp_moders[i] = { name: e.name, id: e.id };
    } else {
      tmp_moders = moderators.filter((e, ind) => ind != i);

      if (tmp_moders.length == 0) tmp_moders.push({ name: "" });
    }

    setModers(tmp_moders);
  }

  function addSpeaker() {
    let tmp_moders = [...moderators];

    if (moderators.length > 0 && moderators[moderators.length - 1].name != "") {
      tmp_moders.push({ name: "" });
      setModers(tmp_moders);
    }
  }

  console.log("S", moderators);

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{text_section_name}</DialogTitle>
      <DialogContent>
        <ul className="speakers-list">
          {moderators.map((item, indexSpeaker) => {
            return (
              <li style={{ marginTop: 10 }}>
                <Autocomplete
                  disablePortal
                  value={item}
                  id="combo-box-demo"
                  options={allSpeakers.filter(
                    (e) => moderators.map((e1) => e1.name).indexOf(e.name) < 0
                  )}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label={text_moderator} />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name ?? ""}</li>
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    onSpeakerTextChange(newValue, indexSpeaker);
                  }}
                />
              </li>
            );
          })}
        </ul>
        <div style={{ marginTop: 10 }} onClick={() => addSpeaker(mainIndex)}>
          <Button variant="outlined">{text_add}</Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{text_save}</Button>
        <Button onClick={cancelData}>{text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
