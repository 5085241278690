export default function StarNotActive(props) {
  return (
     <svg
              width="30"
              height="29"
              viewBox="0 0 30 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7177 2.06477L19.5604 7.80654C19.839 8.37022 20.3767 8.76108 21.0003 8.85153L27.3594 9.777C28.9306 10.0063 29.5558 11.9316 28.4187 13.0347L23.8203 17.5021C23.3684 17.9415 23.1627 18.573 23.2696 19.1932L24.3548 25.5002C24.6221 27.0605 22.9796 28.2508 21.5753 27.5127L15.8917 24.5328C15.3345 24.2405 14.6671 24.2405 14.1083 24.5328L8.42467 27.5127C7.02036 28.2508 5.37795 27.0605 5.64682 25.5002L6.73043 19.1932C6.83733 18.573 6.63162 17.9415 6.17972 17.5021L1.58128 13.0347C0.444224 11.9316 1.06944 10.0063 2.64059 9.777L8.99968 8.85153C9.62328 8.76108 10.1627 8.37022 10.4412 7.80654L13.2823 2.06477C13.9852 0.645076 16.0148 0.645076 16.7177 2.06477Z"
                fill="#FAFF00"
                stroke="#243341"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
  );
} 