import React from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { EntityPage } from "../../_components/EntityPage/EntityPage";
import { reduxLoader } from "../../_services/reduxLoader.service";
import Checkbox from "@mui/material/Checkbox";
import { userService } from "../../_services";
import { store } from "../../_helpers";
import { userActions } from "../../_actions";
import "../../css/Pages/TasksPage.css";

class TasksPage extends React.Component {
  STATUS_NEW = "new";
  STATUS_DONE = "done";

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
    };
  }

  componentDidMount() {
    reduxLoader.team();
    reduxLoader.orgConferences();
  }

  prepareTeamOptions = () => {
    let options = { [EntityPage.NULL_FILLER]: i18next.t("all_team") };
    this.props.team.forEach(function (value) {
      options[value.id] = value.name ? value.name : value.email;
    });
    return options;
  };

  prepareOrgConferencesOptions = () => {
    let options = {};
    this.props.orgConferences.forEach(function (value) {
      options[value.id] = value.name;
    });
    return options;
  };

  entityToText = (data) => {
    let entityType = "";
    switch (data.entity_type) {
      case EntityPage.DIALOG_FIELDS_TYPE_CONFERENCE:
        entityType = i18next.t("conference");
        break;
      default:
    }
    return (
      entityType + " " + this.prepareOrgConferencesOptions()[data.entity_id]
    );
  };

  doneCheckbox = (data) => {
    return (
      <Checkbox
        checked={data.status === this.STATUS_DONE}
        disabled={data.status === this.STATUS_DONE}
        onChange={(e) => {
          store.dispatch(userActions.loading(true));
          userService.patchWrapper(
            `api/task/${data.id}/done`,
            (response) => {
              this.getEntities();
            },
            (error) => {
              store.dispatch(userActions.loading(false));
            }
          );
        }}
      />
    );
  };

  getEntities = () => {
    store.dispatch(userActions.loading(true));
    userService.getWrapper(
      "api/task",
      (data) => {
        store.dispatch(userActions.loading(false));
        if (data.data) {
          this.setState({ entities: [...data.data] });
        }
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        if (error !== "") {
          this.setState({ error });
          alert(error);
        }
      }
    );
  };

  render() {
    return (
      <EntityPage
        loader={this.getEntities}
        header={{
          title: i18next.t("tasks"),
          description: i18next.t("only_those_tasks_where_you_creator_executor"),
          actions: {
            add: { title: i18next.t("new_task") },
          },
        }}
        list={{
          entities: this.state.entities,
          classes: { row: (item) => `TasksPage_row_${item.status}` },
          fields: [
            {
              name: "status",
              convertor: this.doneCheckbox,
              class: "TasksPage_field_checkbox",
              head: i18next.t("status"),
            },
            {
              name: "name",
              class: "tbl__item--text",
              head: i18next.t("name"),
            },
            {
              name: "creator_id",
              convertor: (data) => this.prepareTeamOptions()[data.creator_id],
              class: "tbl__item--text-narrow",
              head: i18next.t("created_by"),
            },
            {
              name: "actor_id",
              convertor: (data) =>
                this.prepareTeamOptions()[
                  data.actor_id ? data.actor_id : EntityPage.NULL_FILLER
                ],
              class: "tbl__item--text-narrow",
              head: i18next.t("executor"),
            },
            {
              name: "entity_id",
              convertor: this.entityToText,
              class: "tbl__item--text-narrow",
              head: i18next.t("where"),
            },
            {
              name: "description",
              class: "tbl__item--text-full",
              head: i18next.t("dialog_desc"),
            },
          ],
          actions: (item) =>
            item.status === this.STATUS_NEW ? ["edit", "delete"] : [],
        }}
        dialog={{
          title: i18next.t("task"),
          fields: {
            entity_type: {
              default: EntityPage.DIALOG_FIELDS_TYPE_CONFERENCE,
              required: true,
              add: true,
              edit: false,
              type: "hidden",
            },
            entity_id: {
              default: "",
              required: true,
              add: true,
              edit: false,
              type: "select",
              options: this.prepareOrgConferencesOptions(),
              label: i18next.t("conference"),
            },
            actor_id: {
              default: null,
              required: false,
              add: true,
              edit: true,
              type: "select",
              options: this.prepareTeamOptions(),
              label: i18next.t("executor"),
            },
            name: {
              default: "",
              required: true,
              add: true,
              edit: true,
              type: "text",
              label: i18next.t("name"),
            },
            description: {
              default: null,
              required: false,
              add: true,
              edit: true,
              type: "text",
              options: { multiline: true },
              label: i18next.t("dialog_desc"),
            },
          },
        }}
        endpoints={{
          add: "api/task",
          edit: "api/task",
          delete: "api/task",
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { team, orgConferences } = state;

  return {
    team,
    orgConferences,
  };
}

const connectedTasksPage = connect(mapStateToProps)(TasksPage);
export { connectedTasksPage as TasksPage };
