import i18next from "i18next";
import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";



import "../css/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 250,
  maxWidth: 320,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};



export default function QRDialog(props) {
	
  const [count, setCount] = React.useState(0);

  const { handleClose, qrData, dialogOpen } = props;

  React.useEffect(() => {});

  function cancelData() {
    handleClose(-1);
    setCount(0);
  }

  function selectTicket(id) {
    handleClose(id);
    setCount(0);
  }

  return (
   
	  <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={dialogOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={dialogOpen}>
        <Box sx={style}>
		<div style={{fontSize:20}}>{i18next.t("ticket_qr")}</div>
           {qrData && <div dangerouslySetInnerHTML={{__html:  qrData}} />
		  }
        </Box>
      </Fade>
    </Modal>
  );
}
