import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import FormatIcons from "../_components/FormatIcons";
import "../css/constructor.css";
import "../css/style.css";
import SelectRoom from "./SelectRoom";
import SelectSpeaker from "./SelectSpeaker";
import SelectTheme from "./SelectTheme";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useTranslation, Trans } from "react-i18next";
import { Checkbox } from "@material-ui/core";

const STATUS_INVITED = "invited";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";
const STATUS_CONTRACT_SENT = "contract_sent";
const STATUS_CONTRACT_SIGNED = "contract_signed";
const STATUS_ACT_SIGNED = "act_signed";
const STATUS_WAITING_FOR_PAYMENT = "waiting_for_payment";
const STATUS_PAID = "paid";


function SpeakersList(props) {
  const {
    speakers,
    mainIndex,
    index,
    delSpeaker,
    addSpeaker,
    allSpeakers,
    onSpeakerTextChange,
    onSpeakerContractChange,
    onSpeakerPartipChange,
    saveTime,
    item,
    showDeclined,
    
  } = props;

  
  const { t, i18n } = useTranslation();

  const filteredSpeakers = [];

  for (let i = 0; i < allSpeakers.length; i++) {
    let isAdded = false;
    for (let j = 0; j < speakers.length; j++) {
      if (speakers[j].name == allSpeakers[i]) {
        isAdded = true;
        break;
      }
    }
    if (isAdded == false) {
      filteredSpeakers.push(allSpeakers[i]);
    }
  }


  if (item.entity && item.entity.id)
    return (
      <ul className="speakers-list">
        {speakers
          .filter((e) => showDeclined || e.status != STATUS_DECLINED)
          .map((item, indexSpeaker) => {
            let iName = "";
            if (item && item.name) iName = item.name;

            return (
              <li>
                <Droppable
                  droppableId={`speaker_${mainIndex}_${index}_${indexSpeaker}`}
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="speaker-choose"
                    >
                      <SelectSpeaker
                        data={filteredSpeakers}
                        item={iName}
                        onChange={(e) => onSpeakerTextChange(e, indexSpeaker)}
                        text_select_speaker={t("select_speaker")}
                      />
                    </div>
                  )}
                </Droppable>
                {iName != "" && (
                  <div
                    className="calendar-block__col status-speaker"
                    title={t("select_status")}
                  >
                    <span>{t("participation")}</span>

                    <select
                      className="calendar-block__select"
                      onChange={(e) => onSpeakerPartipChange(e, indexSpeaker)}
                    >
                      {item.status == "1" ? (
                        <option value="1" selected>
                          {t("not_selected")}
                        </option>
                      ) : (
                        <option value="1">{t("not_selected")}</option>
                      )}
                      {item.status == STATUS_INVITED ? (
                        <option value={STATUS_INVITED} selected>
                          {t("part_requested")}
                        </option>
                      ) : (
                        <option value={STATUS_INVITED}>
                          {t("part_requested")}
                        </option>
                      )}
                      {item.status == STATUS_DECLINED ? (
                        <option value={STATUS_DECLINED} selected>
                          {t("part_declined")}
                        </option>
                      ) : (
                        <option value={STATUS_DECLINED}>
                          {t("part_declined")}
                        </option>
                      )}
                      {item.status == "approved" ? (
                        <option value="approved" selected>
                          {t("approved")}
                        </option>
                      ) : (
                        <option value="approved">{t("approved")}</option>
                      )}
                      {item.status == "without_contract" ? (
                        <option value="without_contract" selected>
                          {t("without_contract")}
                        </option>
                      ) : (
                        <option value="approved">{t("without_contract")}</option>
                      )}

                      {item.status == "contract_sent" ? (
                        <option value="contract_sent" selected>
                          {t("contract_sent")}
                        </option>
                      ) : (
                        <option value="contract_sent">{t("contract_sent")}</option>
                      )}
                      {item.status == "act_signed" ? (
                        <option value="act_signed" selected>
                          {t("act_signed")}
                        </option>
                      ) : (
                        <option value="act_signed"> {t("act_signed")}</option>
                      )}
                      {item.status == "waiting_for_payment" ? (
                        <option value="waiting_for_payment" selected>
                           {t("waiting_for_payment")}
                        </option>
                      ) : (
                        <option value="waiting_for_payment">{t("waiting_for_payment")}</option>
                      )}
                      {item.status == "paid" ? (
                        <option value="paid" selected>
                          {t("act_paid")}
                        </option>
                      ) : (
                        <option value="paid">{t("act_paid")}</option>
                      )}
                    </select>
                  </div>
                )}

                <div
                  className="addSpeaker"
                  onClick={() => addSpeaker(index, mainIndex)}
                >
                  <i className="icon-plus-border" />
                </div>
              </li>
            );
          })}
      </ul>
    );

  return null;
}

export default function SlotItem(props) {
  const {
    item,
    index,
    mainIndex,
    delTheme,
    delRoom,
    delSpeaker,
    themes,
    rooms,
    changeThemes,
    changeRoom,
    delSlot,
    unbindSlot,
    addSpeaker,
    changeSpeakers,
    speakers,
    changeSpeakerPartip,
    saveTime,
    changeMode,
    saveUrl,
    moveSlot,
    plusDuration,
    minusDuration,
    showDeclined,
    editTheme,
    format,
    moveUp,
    moveDown,
    setBookable,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const [focus, setFocus] = React.useState(false);
  const [focusUrl, setFocusUrl] = React.useState(false);
  const [startTime, setTime] = React.useState(null);
  const [oldTime, setOldTime] = React.useState(null);
  const [oldDur, setOldDur] = React.useState(null);
  const [oldUrl, setOldUrl] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [id, setId] = React.useState(null);
  
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (count == 0) 
    {
      setId(item.id);

      if (item.url_online) {
        setUrl(item.url_online);
      } else setUrl("");

      setTime(item.start_time ? item.start_time : "");
      setDuration(item.duration ? item.duration : "");
      setCount(1);
    }

    if (count > 0 && item.id != id) {
      setCount(0);
    }
  },[item,count]);

  const changeStart = (e) => {
    setTime(e.target.value);
    setCount(count + 1);
  };

  const changeDuration = (e) => {
    setDuration(e.target.value);
    setCount(count + 1);
  };

  const minusDurationL = () => {
    clickSave(-5);
    //      setCount(count + 1);
  };

  const plusDurationL = () => {
      if (duration ==1)
     clickSave(4);
          else
    clickSave(5);
    //      setCount(count + 1);
  };

  const changeUrl = (e) => {
    setUrl(e.target.value);
    setCount(count + 1);
  };

  const onFocusOut = () => {
    if (focus == true) {
      setFocus(false);
      clickSave();
    }
  };

  const onFocusUrlOut = () => {
    if (focusUrl == true) {
      setFocusUrl(false);
      clickSaveUrl();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function clickSave(delta = 0) {
    let newDur = +duration + delta;
    if (newDur < 1) newDur = 1;
    
    saveTime(item, startTime, newDur);
    //if (delta !=0)
    setDuration(newDur);
    //setCount(0);
  }

  function clickSaveUrl() {
    saveUrl(item, url);
    //setCount(0);
  }

  function onTextChange(e, oldVal) {
    changeThemes(e, oldVal);
    setCount(0);
  }

  function onSpeakerTextChange(e, i) {
    changeSpeakers(e, i);
    setCount(0);
  }

  function onSpeakerPartipChange(e, i) {
    changeSpeakerPartip(e.target.value, i);
    setCount(0);
  }

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function clickEditTheme() {
	  
    editTheme(item);
  }

  function delSlotL() {
    setAnchorEl(null);
    delSlot();
  }

  function moveUpL() {
    setAnchorEl(null);
    moveUp(mainIndex, index);
  }

  
  function moveDownL() {
    setAnchorEl(null);
    moveDown(mainIndex, index);
  }

  function clickEditThemeL() {
    setAnchorEl(null);
    clickEditTheme();
  }

  function moveSlotL() {
    setAnchorEl(null);
    moveSlot(mainIndex, id);
  }

  function unbindSlotL() {
    setAnchorEl(null);
    unbindSlot();
  }

  let itemtype = item.type;
  

  if (item.entity && item.entity.type) itemtype = item.entity.type;
  
  if (item.id ==10)
      console.log("III",item);

  if (item.slot_type == "service_lesson") itemtype = format;
  
  return (
    <div className="line-constructor">
      <div className="time">
        <div className="time-value">
          <input
            type="time"
            id="time"
            onBlur={onFocusOut}
            onFocus={() => setFocus(true)}
            onChange={changeStart}
            value={startTime || ""}
            min="00:00"
            max="23:59"
            style={{ width: 110 }}
          />
        </div>
        <FormatIcons
          type={itemtype}
          canChange={itemtype =="lesson" || itemtype =="service_lesson"}
          changeMode={() => changeMode(itemtype, item)}
        />
      </div>
      <div className="move" />
      <div className="duration">
        <div className="duration-item">
          <div
            className="minus"
            //onClick={() => minusDuration(mainIndex, index)}
            onClick={minusDurationL}
          >
            <i className="icon-minus" />
          </div>
          <label htmlFor="">
            <input
              type="number"
              id="duration"
              min={1}
              onBlur={onFocusOut}
              onFocus={() => setFocus(true)}
              onChange={changeDuration}
              value={+duration || undefined}
              style={{ height: 50 }}
            />
            <span>{t("minutes")}</span>
          </label>
          <div
            className="pluse"
            //onClick={() => plusDuration(mainIndex, index)}
            onClick={plusDurationL}
          >
            <i className="icon-plus" />
          </div>
        </div>
      </div>
      <div className="theme" style={{ display: "flex", flexDirection: "row" }}>
        <div className="theme-item" style={{ display: "flex", flex: 1 }}>
          <Droppable droppableId={`theme_${mainIndex}_${index}`}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="input-wrap"
              >
                <div className="slot_wrapper">
                  {item.slot_type == "lesson" && (
                    <SelectTheme
                      data={themes}
                      item={
                        item.entity && item.entity.name ? item.entity.name : ""
                      }
                      onChange={onTextChange}
                    />
                  )}
                  {!item.slot_type && (
                    <SelectTheme
                      data={themes}
                      item={item.theme ? item.theme : ""}
                      onChange={onTextChange}
                    />
                  )}
                  {item.slot_type == "service_lesson" && (
                    <span>{item.entity != null && item.entity.name}</span>
                  )}
                  
                </div>
              </div>
            )}
          </Droppable>
        </div>
        <div
          title={t("del_slot")}
          style={{ marginTop: 10, cursor: "pointer",marginRight:10}}
        >
          {item.slot_type === "service_lesson" && (
                    <DeleteIcon onClick={() => changeThemes(null, "")} />
                  )}
        </div>
        <div
          title={t("edit_theme")}
          style={{ marginTop: 10, cursor: "pointer" }}
        >
          {(item.theme || (item.entity && item.entity.name)) && (
            <EditIcon width="15" height="15" onClick={clickEditTheme} />
          )}
        </div>
      </div>
      <div className="speaker">
        {item.slot_type !== "service_lesson" && <SpeakersList
          speakers={
            item.entity && item.entity.speakers ? item.entity.speakers : []
          }
          mainIndex={mainIndex}
          index={index}
          item={item}
          allSpeakers={speakers}
          addSpeaker={addSpeaker}
          onSpeakerTextChange={onSpeakerTextChange}
          onSpeakerPartipChange={onSpeakerPartipChange}
          delSpeaker={delSpeaker}
          showDeclined={showDeclined}
        />}
      </div>
      <div className="room">
        <div className="room-item">
          {((itemtype == "hybrid" || itemtype == "offline")  || format=="offline") && (
            <Droppable droppableId={`room_${mainIndex}_${index}`}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="input-wrap"
                >
                  <div className="slot_wrapper">
                    <SelectRoom
                      data={rooms}
                      id={item.room_id ? item.room_id : 0}
                      item={item.room ? item.room : ""}
                      onChange={(e) => changeRoom(e, item)}
                      text_select_room={t("select_room")}
                    />
                  </div>
                </div>
              )}
            </Droppable>
          )}
          {((itemtype == "hybrid" || itemtype == "online") || format=="online")&& (
            <input
              style={{ marginTop: 5 }}
              placeholder={t("url_translation")}
              onBlur={onFocusUrlOut}
              onFocus={() => setFocusUrl(true)}
              onChange={changeUrl}
              value={url}
            />
          )}
        </div>
      </div>
      <div
        className="options"
        style={{ marginTop: 10, marginLeft: 10, width: 40 }}
      >
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={moveSlotL}>{t("move_to_section")}</MenuItem>
          {mainIndex >= 0 && (
            <MenuItem onClick={unbindSlotL}>{t("unbind_of_section")}</MenuItem>
          )}
          {(item.theme || (item.entity && item.entity.name)) && (
            <MenuItem onClick={clickEditThemeL}>{t("edit_theme")}</MenuItem>
          )}
          <MenuItem onClick={moveUpL}>{t("move_up")}</MenuItem>
          <MenuItem onClick={moveDownL}>{t("move_down")}</MenuItem>
          <MenuItem onClick={delSlotL}>{t("del_slot")}</MenuItem>
          <div title={t("bukable_hint")}>
              <Checkbox checked={item.entity && item.entity.available_seats !=null} onClick={(e)=>setBookable(e,item.id, index)} />
              <span style={{ fontSize: 18 }}>{t("make_bookable")}</span>
            </div>
        </Menu>
        <MoreIcon onClick={openMenu} />
      </div>
    </div>
  );
}
