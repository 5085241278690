import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../css/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { serverUrl } from "../_constants";

export default function BuildingsItem(props) {
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div
          className="tbl__item tbl__item--name"
          onClick={props.onClick}
          style={{ textDecoration: "underline", color: "blue" }}
        >
          <span className="profile">
            <span className="profile__name">
              {props.data ? props.data.name : ""}
            </span>
          </span>
        </div>

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.email : ""}</span>
        </div>

        <div className="tbl__item tbl__item--city">
          <span>{props.data ? props.data.address : ""}</span>
        </div>

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.person : ""}</span>
        </div>

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.phone : ""}</span>
        </div>

        <div className="tbl__item tbl__item--city">
          <span>{props.data ? props.data.comment : ""}</span>
        </div>

        <div className="item-edit">
          <div
            style={{
              width: 20,
              marginLeft: 10,
              marginRight: 10,
              cursor: "pointer",
            }}
          >
            <EditIcon
              width="15"
              height="15"
              onClick={(e) => props.editBld(props.data.id)}
            />
          </div>
          <div style={{ width: 20, cursor: "pointer" }}>
            <DeleteIcon
              width="15"
              height="15"
              onClick={(e) => props.delBld(props.data.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
